<script context="module">
  import { param, params } from "./util/params";
  import { derived } from "svelte/store";
  export const inspect = derived(
    param("inspect"),
    ($value) => $value === "true" || $value === true
  );
  export const allspaces = derived(
    param("allspaces"),
    ($value) => $value === "true" || $value === true
  );
  export const allunits = derived(
    param("allunits"),
    ($value) => $value === "true" || $value === true
  );
  export const style = param("style");
</script>

<script>
  import Map from "./components/maps/Map.svelte";
  import MapCompass from "./components/maps/MapCompass.svelte";
  import MapMarker from "./components/maps/MapMarker.svelte";
  import MapPopup from "./components/maps/MapPopup.svelte";
  import MapPitch from "./components/maps/MapPitch.svelte";
  import MapInspector from "./components/maps/MapInspector.svelte";
  import MapAttribution from "./components/maps/MapAttribution.svelte";
  import EditSpacePrices from "./components/EditSpacePrices.svelte";
  import {
    propertyGeoJsonCustom,
    propertyGeoJsonStatic,
    level,
    mapbranch,
    custom,
  } from "./components/maps/stores";
  import { query } from "./util/router";
  import {
    property,
    selectedId,
    unitId,
    spaceId,
    permitted,
    permits,
    selected,
    valid,
    mode,
    parking,
    advanced,
    admin,
  } from "./util/stores";
  import { items } from "@parkingboss/svelte-utils";
  import {
    processFeatures,
    filterLevel,
    processPrices,
    //processSpaceRecords,
    //processUnitRecords,
    processSpacePermitStatus,
    processSpacePolicies,
    processSelected,
    processUnitPermitStatus,
    filters,
  } from "./util/geopipeline";
  import { toZoneISOString } from "./util/datetime";
  import { format, startOfDay, addDays } from "date-fns";
  import { sortBy, numericAsc } from "./util/sort";
  import { token } from "./util/auth";
  import { scaleBbox, bbox } from "./util/geo";
  import { styles } from "@communityboss/carto";

  //const styles = { cool, warm, satellite };

  $: currentStyle = styles[$style || styles.default || "cool"];

  $: console.log("style=", currentStyle);

  let mapbox;
  let iframe;
  let attribution;

  let sources = {};
  let levels = {};
  let points = [];
  let unitPoints = [];
  let levelPoints = [];
  let activeBbox;
  let selectedLevel;
  let selectedPoints = [];
  let highlighters = [
    {
      key: "ev",
      value: "yes",
      evaluator: filters["ev"],
      title: "Charging",
      count: 0,
      active: false,
    },
    {
      key: "ada",
      value: "yes",
      evaluator: filters["ada"],
      title: "ADA",
      count: 0,
      active: false,
    },
    {
      key: "compact",
      value: "yes",
      evaluator: (feature) => filters["size"](feature, "compact"),
      title: "Compact",
      count: 0,
      active: false,
    },
    {
      key: "tandem",
      value: "yes",
      evaluator: (feature) =>
        filters["size"](feature, "multiple") ||
        filters["size"](feature, "tandem"),
      title: "Tandem",
      count: 0,
      active: false,
    },
    {
      key: "prospect",
      value: "prospect",
      evaluator: filters["access"],
      title: "Leasing",
      count: 0,
      active: false,
    },
    {
      key: "customer",
      value: "customer",
      evaluator: filters["access"],
      title: "Retail",
      count: 0,
      active: false,
    },
    {
      key: "parking_space",
      value: "yes",
      evaluator: (feature) => filters["parking_space"](feature),
      title: "Spaces",
      count: 0,
      active: false,
    },
    {
      key: "motorcycle",
      value: "yes",
      evaluator: (feature) => filters["parking_space"](feature, "motorcycle"),
      title: "Motorcycle",
      count: 0,
      active: false,
    },
  ];

  const selectLayer = "property subject";
  const selectOutlineLayer = "property selectable outline";

  //$: console.log("levels=", levels);

  //$: highlighters = activeHighlighters(highlighters, $params);

  let availableSpaces = [];
  //let policyFeatures = [];

  //let dynamicFeatures = {};

  function activeHighlighters(highlighters, $params) {
    for (const highlighter of highlighters) {
      highlighter.active = $params[highlighter.key] == highlighter.value;
      console.log("highlighter=", highlighter);
    }
    return highlighters;
  }

  $: title = document.title = $property
    ? `${$property ? $property.name + " " : ""} Map - Community Boss`
    : "Loading…";

  // $: if($propertyGeoJsonDynamic) dynamicFeatures = $propertyGeoJsonDynamic.features.reduce((result, item) => {
  //     result[item.id] = item;
  //     return result;
  // }, {});

  //$: console.log("dynamic features = ", dynamicFeatures);

  // run pipeline on any change
  $: features = pipeline(
    [$propertyGeoJsonStatic],
    $items,
    $permitted,
    $parking
  );

  $: selectAndLevel(
    features,
    points,
    $level,
    [$selectedId, $spaceId, $unitId],
    $params
  );

  function pipeline(geojson, state, spaceStatus, parking) {
    if (!geojson) return;

    const spaces = [];
    const units = [];
    let features = [];
    //const newLevels = {};

    attribution = geojson.reduce(
      (result, geojson) =>
        geojson.properties && geojson.properties.branch
          ? `${geojson.properties.branch} ${geojson.properties.commit} ${geojson.properties.build}`
          : result,
      null
    );

    const allFeatures = geojson.flatMap(
      (geojson) => (geojson && geojson.features) || geojson || []
    );

    console.log("bboxing all features=", allFeatures);

    activeBbox =
      allFeatures.length &&
      bbox({
        type: "FeatureCollection",
        features: allFeatures,
      });
    console.log("bbox=", activeBbox);

    processFeatures(allFeatures, features, levels, spaces, units);

    //processSpaceRecords(spaces, state && state.spaces && state.spaces.items);
    //processUnitRecords(units, state && state.units && state.units.items);

    processSpacePolicies(spaces, state);

    if (parking == "pricing") {
      processPrices(spaces, state && state.prices && state.prices.items);
    }
    if (parking == "permitted") {
      processSpacePermitStatus(spaces, spaceStatus);
      processUnitPermitStatus(
        units,
        state && state.permits && state.permits.map
      );
    }
    // if(mode == "unitavail") {
    //     processUnitStatus(units, unitStatus);
    // }

    //if(mode == "parking") {
    processSelectable(spaces);
    processSelectable(units);
    //processSelected(units, selected);
    //processSelected(spaces, selected);
    // } else {

    // }

    const mapAvailableSpaces = {};

    for (const feature of spaces) {
      if (!feature.properties["ref:boss:subject"]) continue;
      if (!feature.properties.permit || feature.properties.permit != "no")
        continue;
      if (feature.properties["policy"] == "temporary") continue;
      if (mapAvailableSpaces[feature.properties["ref:boss:subject"]]) continue;
      //if(!feature.properties.name) continue;
      mapAvailableSpaces[feature.properties["ref:boss:subject"]] = feature;
    }

    availableSpaces = sortBy(
      Object.values(mapAvailableSpaces),
      "properties.name"
    );

    //spaceHeadings(spaces, features);

    levels = levels;

    return features;
  }

  // run the selection/leveling
  function selectAndLevel(features, points, level, selected, params) {
    console.log("starting select and level", features, points, level, selected);

    if (!features || !features.length) return;
    //if(!points || !points.length) return;

    // run selection against all features
    // this calcs on each run
    processSelected(features, selected);

    highlighters = processHighlighted(
      features,
      activeHighlighters(highlighters, params)
    );

    // optimize for already selected level
    // this calcs conditionally
    // if(levelFeatures && levelFeatures.length && level === selectedLevel) {
    //     console.log("selected=", selectedLevel, "level=", level, "same level, skipping, keep previous features");
    // } else {
    //console.log("selected=", selectedLevel, "level=", level, "filtering to new level");
    const levelFeatures = filterLevel(features, level).features;

    levelPoints = filterLevel(
      points.concat(
        Object.values(unitPoints).filter(
          (feature) => "yes" == feature.properties.selected
        )
      ),
      level
    ).features.concat();
    selectedLevel = level;
    //hasProcessedLevel = true;
    //}

    // run after any releveling, selected will be updated previously
    // this calcs on each run
    selectedPoints = levelPoints.filter(
      (feature) => "yes" == feature.properties.selected
    );

    console.log("about to render sources, property=", levelFeatures);
    sources = Object.assign(sources, {
      property: {
        type: "geojson",
        generateId: true,
        data: {
          type: "FeatureCollection",
          features: levelFeatures,
        },
        attribution: attribution,
      },
    });
    console.log("just set sources =", sources);
  }

  function processHighlighted(geojson, highlighers) {
    for (const highlighter of highlighters) {
      highlighter.count = 0;
    }

    const features = geojson.features || geojson;
    for (const feature of features) {
      for (const highlighter of highlighters) {
        if (!highlighter.evaluator(feature, highlighter.value)) continue;
        highlighter.count++;
      }

      if (
        !!highlighers.find((highlighter) => {
          return (
            highlighter.active &&
            highlighter.evaluator(feature, highlighter.value)
          );
        })
      ) {
        feature.properties.highlight = "yes";
      } else delete feature.properties.highlight;
    }

    return highlighers;
  }

  function processSelectable(geojson) {
    const features = geojson.features || geojson;
    for (const feature of features) {
      const selectable =
        feature.geometry.type == "Polygon" &&
        feature.properties["ref:boss:subject"];
      //feature.geometry.type == "Polygon" && feature.properties["addr:unit"] && feature.properties["ref:boss:subject"] && feature.properties["charge"]
      //|| feature.geometry.type == "Polygon" && feature.properties.amenity == "parking_space" && feature.properties["ref:boss:subject"]

      if (selectable) feature.properties.selectable = "yes";
      else delete feature.properties.selectable;
    }
  }

  let hovered = null;

  function record(item) {
    //console.log("record=", item);
    return `<data value="${item.id}" class="id ${[item.format, item.type]
      .filter((i) => !!i)
      .join(" ")}">${item.display || item.name}${[
      "color",
      "covered",
      "indoor",
      "capacity:charging",
      "capacity:disabled",
      "capacity:lift",
      "capacity:bicycle",
      "size",
    ]
      .filter((key) => !!item[key])
      .map(
        (key) =>
          `<data class="${key.replace(":", "-")}" value="${item[
            key
          ].toLowerCase()}"></data>`
      )
      .join("")}</data>`;
  }

  const featureDescription = function (feature) {
    if (!feature) return null;
    if (!feature.properties["ref:boss:subject"]) return null;

    const html = [];

    var id = feature.properties["ref:boss:subject"];

    const state = $items;

    const meta = {
      media: [],
      space: [],
      vehicle: [],
      tenant: [],
      unit: [],
      policy: [],
    };

    if (state && state.permits && state.permits.map && state.permits.map[id]) {
      for (const subject of Object.values(state.permits.map[id])) {
        //console.log("subject=", subject);
        if (!subject.id) continue;
        if (subject.type == "policy") continue;
        meta[subject.type].push(`<li>${record(subject)}</li>`);
        //console.log(meta[subject.type]);
      }
    }
    if (
      state &&
      state.policies &&
      state.policies.map &&
      state.policies.map[id]
    ) {
      for (const policy of Object.values(state.policies.map[id])) {
        meta["policy"].push(
          `<li><data value="${policy.id}" class="policy">${policy.title}</data></li>`
        );
      }
    }

    if (feature.properties["addr:unit"]) {
      html.push(`<h1>${record({ ...feature.properties, type: "unit" })}</h1>`);
      //console.log(feature.properties, record(feature.properties));
    }

    if (feature.properties.amenity == "parking_space") {
      html.push(`<h1>${record({ ...feature.properties, type: "space" })}</h1>`);
      //if(feature.properties.charge) html.push(`<data value="${feature.properties["ref:boss:subject"]}" class="charge">${feature.properties.charge}</data>`);
      //console.log(feature.properties, record(feature.properties));
    }

    if (state && state.prices && state.prices.items && state.prices.items[id]) {
      for (const price of state.prices.items[id]) {
        html.push(
          `<data value="${price.id}" class="price">${price.total.display}</data>`
        );
      }
    }

    if (feature.properties["addr:unit"]) {
      html.push("<ul>");
      html.push(...meta.space);
      html.push(...meta.vehicle);
      html.push(...meta.media);
      html.push("</ul>");
    }

    if (feature.properties.amenity == "parking_space") {
      if (meta.policy.length) {
        //console.log("policy=", ...meta.policy);
        html.push("<ul>");
        html.push(...meta.policy);
        //for (const subject of meta.policy) html.push(subject);
        html.push("</ul>");
      }

      if (feature.properties.capacity == 1) {
        html.push("<ul>");
        html.push(...meta.tenant);
        html.push(...meta.unit);
        html.push(...meta.vehicle);
        html.push(...meta.media);
        html.push("</ul>");
      }
    }

    return html.join("");
  };

  function click(e) {
    const feature = e.features[0];
    if (!feature) return;

    if (feature.properties["ref:boss:subject"]) {
      return query("selected", feature.properties["ref:boss:subject"]);
    }

    if (
      feature.properties["addr:unit"] &&
      feature.properties["ref:boss:subject"]
    ) {
      query("unit", feature.properties["ref:boss:subject"]);
    }

    if (
      feature.properties["amenity"] == "parking_space" &&
      feature.properties["ref:boss:subject"]
    ) {
      query("space", feature.properties["ref:boss:subject"]);
    }
  }

  // selectable UI
  $: if (mapbox) {
    //mapbox.setPadding({ left: 288 });

    mapbox.dragRotate.disable();

    // disable map rotation using touch rotation gesture
    mapbox.touchZoomRotate.disableRotation();
    mapbox.touchPitch.disable();

    // click to select
    mapbox.on("click", selectLayer, click);

    mapbox.on("mouseenter", (e) => {
      mapbox.getCanvas().style.cursor = "pointer";
    });
    mapbox.on("mousemove", selectLayer, (e) => {
      if (!e.features || !e.features.length) return;

      if (hovered && hovered.id == e.features[0].id) return; // already hovered

      if (hovered) {
        mapbox.setFeatureState(hovered, { hover: false });
      }
      hovered = e.features[0];
      mapbox.setFeatureState(hovered, { hover: true });
    });
    mapbox.on("mouseleave", selectLayer, (e) => {
      mapbox.getCanvas().style.cursor = "";
      if (hovered) {
        mapbox.setFeatureState(hovered, { hover: false });
        hovered = null;
      }
    });
  }

  const adminOrigin = "https://parking.communityboss.app"; //"https://localhost:5001";//
  let selectedAdminUrl;

  $: if (!$selected) selectedAdminUrl = null;
  $: if ($selected) {
    let url =
      "/" +
      $selected.scope +
      "/" +
      ($selected.type == "unit" ? "tenant" : $selected.type) +
      "s/" +
      ($selected.type == "unit" ? $selected.key : $selected.id);
    if (url !== selectedAdminUrl) {
      selectedAdminUrl = url;
    }
  }

  $: if (iframe && selectedAdminUrl) {
    if (!iframe.getAttribute("src")) {
      console.log("navigate via src=", adminOrigin, selectedAdminUrl);
      iframe.setAttribute(
        "src",
        `${adminOrigin}${selectedAdminUrl}?minimal=true#access_token=${$token}`
      );
    } else {
      console.log("navigate via post=", adminOrigin, selectedAdminUrl);
      iframe.contentWindow.postMessage(
        JSON.stringify({
          url: selectedAdminUrl,
        }),
        adminOrigin
      );
    }
  }

  let allMappedSpaces = [];
  let allMappedUnits = [];

  $: if ($allspaces && sources && $propertyGeoJsonStatic) {
    allMappedSpaces = Object.values(
      $propertyGeoJsonStatic.features.reduce((mapped, item) => {
        if (item.geometry.type !== "Polygon") return mapped;
        if (item.properties.amenity !== "parking_space") return mapped;
        if (!item.properties.ref && !item.properties.name) return mapped;
        mapped[item.properties.ref || item.properties.name] =
          item.properties.ref || item.properties.name;
        return mapped;
      }, {})
    ).sort();
    console.log("allMappedSpaces=", allMappedSpaces);
  }

  $: if ($allunits && sources && $propertyGeoJsonStatic) {
    allMappedUnits = Object.values(
      $propertyGeoJsonStatic.features.reduce((mapped, item) => {
        if (item.geometry.type !== "Polygon") return mapped;
        if (!item.properties["addr:unit"]) return mapped;
        mapped[item.properties["addr:unit"]] = item.properties["addr:unit"];
        return mapped;
      }, {})
    ).sort();
    //console.log("allMappedSpaces=", allMappedSpaces);
  }

  let policyUrl;
  $: policyUrl = $selected && $selected.url;

  function select(feature) {
    console.log("select=", feature);
    query({
      selected: feature.properties["ref:boss:subject"] || feature.id,
      level: feature.properties.level,
    });
  }

  function readFileAsText(file) {
    return new Promise(function (resolve, reject) {
      const reader = new FileReader();
      reader.onload = (event) => resolve(event.target.result); // desired file content
      reader.onerror = (error) => reject(error);
      reader.readAsText(file); // you could also read images and other binaries
    });
  }

  async function readFileAsJSON(file) {
    return JSON.parse(await readFileAsText(file));
  }

  async function file(e) {
    //console.log(e);

    var items =
      (e.target && e.target.files) ||
      (e.dataTransfer && e.dataTransfer.files) ||
      [];
    //console.log("files=", items);
    if (!items.length) return;

    const item = items[0];

    if (e.target) e.target.value = "";

    var json = await readFileAsJSON(item);
    propertyGeoJsonCustom.set(json);

    e.preventDefault();
  }

  function floorName(level = null) {
    if (null == level || "" == level) return "Outside";
    return levels[level] || "Floor " + level;
  }
</script>

<svelte:head>
  <title>{title}</title>
</svelte:head>
<header />

<main>
  <section>
    <!-- <h1>{($property && $property.name) || "Loading…"}</h1> -->

    <form on:submit|preventDefault={(e) => e}>
      <fieldset>
        <ul>
          <!-- {#if $advanced} -->
          <!-- <li on:change={e => (e.target.checked && query(e.target.name, e.target.value))}>
                    <label for="display-mode">Display</label>
                    <ul>
                        <li><input id="display-mode-parking" type="radio" name="mode" checked={"parking" == $mode} value="" /><label for="display-mode-parking">parking</label></li>
                        <li><input id="display-mode-amenities" type="radio" name="mode" checked={"amenities" == $mode} value="amenities" /><label for="display-mode-amenities">amenities</label></li>
                    </ul>
                </li> -->
          {#if true || $mode == "parking"}
            <li
              on:change={(e) =>
                e.target.checked && query(e.target.name, e.target.value)}
            >
              <label for="display-mode">Parking</label>
              <ul>
                <li>
                  <input
                    id="display-parking-permits"
                    type="radio"
                    name="parking"
                    checked={"permitted" == $parking}
                    value=""
                  /><label for="display-parking-permits">permits</label>
                </li>
                <li>
                  <input
                    id="display-parking-pricing"
                    type="radio"
                    name="parking"
                    checked={"pricing" == $parking}
                    value="pricing"
                  /><label for="display-parking-pricing">pricing</label>
                </li>
              </ul>
            </li>

            <!-- {/if} -->
            <li
              on:change={(e) =>
                e.target.checked && query(e.target.name, e.target.value)}
            >
              <label>Level</label>
              <ul>
                <li>
                  <input
                    id="level-0"
                    type="radio"
                    name="level"
                    checked={!$level}
                    value=""
                  /><label for="level-0">{floorName()}</label>
                </li>
                {#if levels && Object.keys(levels).length > 0}
                  {#each Object.keys(levels).sort(numericAsc) as id}
                    <li>
                      <input
                        id="level-{id}"
                        type="radio"
                        name="level"
                        checked={("" == id && !$level) || id == $level}
                        value={id}
                      /><label for="level-{id}">{floorName(id)}</label>
                    </li>
                  {/each}
                {/if}
              </ul>
            </li>
            <!-- {#if levels && levels.length > 0}
                <li on:change={e => (e.target.checked && query(e.target.name, e.target.value))}>
                    <label>Inside</label>
                    <ul>
                        
                        {#each [ ...levels ].sort((a, b) => (a[0] || 0) - (b[0] || 0)) as [ id, name ]}
                        <li><input id="level-{id}" type="radio" name="level" checked={(""==id && !$level) || (id == $level)} value="{id}" /><label for="level-{id}">{name}</label></li>
                        {/each}
                        
                    </ul>
                    
                </li>
                {/if} -->
          {/if}
          {#if $parking == "permitted"}
            {#if $allspaces}
              <li>
                <label for="spaces-all">Mapped</label>
                <textarea rows="10">{allMappedSpaces.join("\r")}</textarea>
              </li>
            {/if}
            {#if $allunits}
              <li>
                <label for="units-all">Units</label>
                <textarea rows="10">{allMappedUnits.join("\r")}</textarea>
              </li>
            {/if}
            <li>
              <label for="spaces-available">Available</label>
              {#if $permits && $permitted}
                <select
                  name="valid"
                  on:change={(e) => query(e.target.name, e.target.value)}
                  on:blur={(e) => query(e.target.name, e.target.value)}
                  value={$valid}
                >
                  <option value="">today</option> -->

                  {#each Array.from( { length: 60 }, (v, i) => addDays(startOfDay(new Date()), i + 1) ) as min}
                    <option
                      value="{toZoneISOString(
                        min,
                        $property && $property.timezone
                      )}/">{format(min, "EE MMM d")}</option
                    >
                  {/each}
                </select>
              {:else}
                <input type="text" disabled value="Loading…" />
              {/if}
            </li>
            <li>
              <label
                >{availableSpaces.length}
                {availableSpaces.length != 1 ? "Spaces" : "Space"}</label
              >
              <ul>
                {#each availableSpaces as feature}
                  <li>
                    <input
                      id="space-{feature.properties['ref:boss:subject']}"
                      type="radio"
                      name="selected"
                      checked={feature.properties["ref:boss:subject"] ==
                        $selectedId}
                      value={feature.properties["ref:boss:subject"]}
                      on:change={(e) => e.target.checked && select(feature)}
                    />
                    <label for="space-{feature.properties['ref:boss:subject']}"
                      ><data
                        class="space id"
                        value={feature.properties["ref:boss:subject"]}
                        >{feature.properties["name"]}</data
                      ></label
                    >
                  </li>
                {/each}
              </ul>
            </li>
            <li>
              <label>Highlight</label>
              <ul>
                {#each highlighters as highlighter}
                  <li>
                    <input
                      id="highlight-{highlighter.key}"
                      type="checkbox"
                      name={highlighter.key}
                      checked={highlighter.active}
                      value={highlighter.value}
                      on:change={(e) =>
                        query(
                          e.target.name,
                          e.target.checked && e.target.value
                        )}
                    />
                    <label for="highligher-{highlighter.key}"
                      >{highlighter.title} ({highlighter.count})</label
                    >
                  </li>
                {/each}
              </ul>
            </li>
          {/if}
          {#if $advanced}
            <!-- <li on:change={(e) => query(e.target.name, e.target.value)}>
              <label for="map-branch">Version</label>
              <select id="map-branch" name="branch" value={$mapbranch}>
                <option value="main">live</option>
                <option value="develop">development</option>
                <option value="preflight">preflight</option>
              </select>
            </li> -->
            <li>
              <label for="map-geojson-upload">Upload</label>
              <input type="file" accept=".json" on:change={file} />
            </li>
            {#if $custom}
              <li on:change={(e) => query(e.target.name, e.target.value)}>
                <label for="map-geojson">Custom</label>
                <select id="map-geojson" name="geojson" value={$custom}>
                  <option value="">no</option>
                  <option value={$custom}>yes</option>
                </select>
              </li>
            {/if}
            <li on:change={(e) => query(e.target.name, e.target.value)}>
              <label for="map-inspect">Inspect</label>
              <select
                id="map-inspect"
                name="inspect"
                value={($inspect || "") + ""}
              >
                <option value="">no</option>
                <option value="true">yes</option>
              </select>
            </li>
            <li on:change={(e) => query(e.target.name, e.target.value)}>
              <label for="map-style">Style</label>
              <select
                id="map-style"
                name="style"
                value={($style || "cool") + ""}
              >
                {#each Object.entries(styles) as [id, style]}
                  <option value={id}>{style.name}</option>
                {/each}
              </select>
            </li>
          {/if}
        </ul>
      </fieldset>
    </form>
  </section>
  <Map
    bind:mapbox
    style={currentStyle}
    {sources}
    maxBbox={activeBbox && scaleBbox(activeBbox, 4)}
  >
    <!-- <MapCompass /> -->
    <!-- <MapPitch /> -->
    <MapAttribution {attribution} />
    <MapPopup feature={hovered} contents={featureDescription} />
    {#if $inspect}
      <MapInspector />
    {/if}
    <!-- {#if true || $mode == "amenities"}
    {#each markerFeatures as feature}
        <MapMarker feature={feature} selected={feature.properties.selected == "yes"} on:selected={e => (query("selected", e.detail.properties.subject))} />
    {/each}
    {/if}
    {#if $propertyMapillary}
    {#each $propertyMapillary.features as feature}
        <MapMarker feature={feature} />
    {/each}
    {/if} -->
  </Map>
  {#if $admin}
    <figure class="editor" class:active={!!selectedAdminUrl}>
      <button type="button" on:click={(e) => query("selected", null)}
        >Close</button
      >
      <iframe bind:this={iframe} />
    </figure>
  {/if}
  <figure class="editor policy" class:active={!!policyUrl}>
    <button type="button" on:click={(e) => query("selected", null)}
      >Close</button
    >
    <iframe src={policyUrl || "about:blank"} />
  </figure>
</main>
