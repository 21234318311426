import { router, end } from "./util/router";
import App from "./App.svelte";
import style from "../styles/app.scss";

//ensureLoggedIn(true);

const app = new App({ target: document.body, props: {} });

function view(viewname) {
    return function(ctx, next) {
        ctx.params.view = viewname;
        next();
    };
}

router("*", end);

router.start();

export default app;