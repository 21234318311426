import bbox from "geojson-bbox";
import bboxPolygon from "@turf/bbox-polygon";
import transformScale from "@turf/transform-scale";

export function scaleBbox(init, scale) {
    var scaled = bbox(transformScale(bboxPolygon(init), 3));
    return scaled;
}

export {
    bbox
}