<script>

    import { onMount, onDestroy, getContext, createEventDispatcher } from "svelte";
	import { mapbox, key } from "./mapbox.js";
	import polylabel from "polylabel";

	const dispatch = createEventDispatcher();

	const { getMap } = getContext(key);
    const map = getMap();

	var popup = new mapbox.Popup({
		closeButton: false,
		closeOnClick: false,
		anchor:"bottom-left",
		className:"boss-overview",
		//offset: [10,-10]
	});

	export let position;
	export let feature;
	export let contents;

	$: html = (typeof(contents) == "function" ? contents(feature) : contents) || "";

	$: point = (feature && feature.geometry.type == "Point" && feature.geometry.coordinates) || (feature && feature.geometry.type == "Polygon" && polylabel(feature.geometry.coordinates)) || position;

	$: if(point) popup.setLngLat(point).setHTML(html).addTo(map);
	else popup.remove();

	onDestroy(function() {
		popup.remove();
	});

</script>