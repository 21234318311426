<script>
  import { onMount, onDestroy, setContext } from "svelte";
  import { key, updateSources, updateStyle, boundsFromGeoJSON } from "./mapbox";
  import mapboxgl from "mapbox-gl";
  import maplibregl from "maplibre-gl";
  import { defer } from "lodash-es";
  // assuming all MAPVIEW gl js/access/css is running

  const lib = mapboxgl;

  export let mapbox;
  export let style;
  export let bbox = null;
  export let maxBbox = null;
  let bounded = false;

  export let sources = {};

  // ability to toggle layer visibility
  export let layers = [];

  export let minzoom = 0;
  export let maxzoom = 22;

  //export let highlight = [];
  export let pitch;
  // export let heading;
  // export let zoom;
  // export let center;

  let extrudePitchThreshold = 0.01;

  // update store on param change

  // map gets rendered with no style
  let MAPVIEW = null;
  let container = null;
  let padding = 10;

  $: if (MAPVIEW) mapbox = MAPVIEW;

  //$: if(MAPVIEW) console.log("map=", MAPVIEW, MAPVIEW.getStyle());

  const resizeObserver = new ResizeObserver(() => {
    console.log("resize!");
    if (MAPVIEW) MAPVIEW.resize();
  });

  $: if (container) resizeObserver.observe(container);

  setContext(key, {
    getMap: () => MAPVIEW,
  });

  /*
    $: if (MAPVIEW && geojson && geojson.features && geojson.features.length && !bounded) {
        //console.log("bound!");
        //if(geojson.bbox) boundsFromBbox(MAPVIEW, geojson.bbox, padding)
        boundsFromGeoJSON(MAPVIEW, geojson, padding);
        bounded = true;
        //if(!center)
    }
    */

  let styleLoaded = false;

  let extrusionLayers;
  function updateMapExtrusionLayers(MAPVIEW) {
    if (extrusionLayers) return;
    extrusionLayers = MAPVIEW.getStyle().layers.filter((layer) => {
      console.log("layer=", layer.type);
      return (
        layer.type === "fill-extrusion" &&
        MAPVIEW.getLayoutProperty(layer.id, "visibility") !== "none"
      );
    });
    console.log("extrusion layers=", extrusionLayers);
    updateExtrusionLayersOnPitch(MAPVIEW);
  }
  function updateExtrusionLayersOnPitch(MAPVIEW) {
    console.log("update extrusion", pitch, extrudePitchThreshold);
    (extrusionLayers || []).forEach((layer) =>
      MAPVIEW.setLayoutProperty(
        layer.id,
        "visibility",
        pitch > extrudePitchThreshold ? "visible" : "none"
      )
    );
  }

  $: if (MAPVIEW) {
    MAPVIEW.on("styledata", () => {
      console.log("styledata");
      styleLoaded = true;
    });
    // update state
    MAPVIEW.on("pitchend", function (e) {
      if (styleLoaded) updateExtrusionLayersOnPitch(MAPVIEW);
    });
  }

  $: if (style) console.log("map style changed=", style);
  $: if (MAPVIEW) console.log("map changed=", MAPVIEW);

  $: if (MAPVIEW && style) {
    styleLoaded = false;
    extrusionLayers = null;
    //console.log("setting style=", style);
    if (typeof style == "string") {
      fetch(style)
        .then((res) => res.json())
        .then((json) => {
          updateStyle(MAPVIEW, json, sources);
          defer(function () {
            if (MAPVIEW.isStyleLoaded()) styleLoaded = true;
          });
        });
    } else {
      updateStyle(MAPVIEW, style, sources);
      defer(function () {
        if (MAPVIEW.isStyleLoaded()) styleLoaded = true;
      });
    }
    //
  }

  $: console.log("styleLoaded", styleLoaded);

  $: if (MAPVIEW && styleLoaded) {
    if (styleLoaded) updateMapExtrusionLayers(MAPVIEW);
  }

  $: if (MAPVIEW && (sources || layers) && styleLoaded) {
    updateSources(MAPVIEW, sources, layers);
  } // update map todata

  $: if (MAPVIEW && bbox && bbox.length == 4) {
    //console.log("fitbounds=", bbox);
    MAPVIEW.fitBounds(bbox, {
      padding: 100,
      animate: false,
    });
  }

  $: if (MAPVIEW && maxBbox && maxBbox.length == 4) {
    //console.log("maxbounds=", maxBbox);
    MAPVIEW.setMaxBounds(maxBbox);
  }

  async function initMap(container) {
    const created = new lib.Map({
      container: container, // container id
      attributionControl: false,
      style,
      //logoPosition:"bottom-right",
      // heading,
      // pitch,
      // center,
      // zoom, // starting zoom
      logoControl: false,
      maxZoom: maxzoom,
      //minZoom:12,
      //dragRotate:false,
      //pitchWithRotate: false
    });
    //created.touchZoomRotate.disableRotation();

    return created;

    // return new Promise(function(resolve) {

    //     created.on("load", async function() {

    //         resolve(created);

    //     });

    // });
  }

  onMount(async () => {
    if (!MAPVIEW) MAPVIEW = await initMap(container);

    // const styleUrl = `https://api.mapbox.com/mapbox-gl-js/v${lib.version}/mapbox-gl.css`;

    // if(document.head.querySelector(`link[href='${styleUrl}']`)) {
    //     if(!MAPVIEW) MAPVIEW = await initMap(container);
    // } else {
    //     const link = document.createElement("link");
    //     link.rel = "stylesheet";
    //     //link.href = 'https://unpkg.com/mapbox-gl/dist/mapbox-gl.css';
    //     link.href = styleUrl;

    //     link.onload = async () => {
    //         if(!MAPVIEW) MAPVIEW = await initMap(container);
    //     };

    //     document.head.appendChild(link);
    // }

    //styleLoaded = false;

    return () => {
      if (MAPVIEW) MAPVIEW.remove();
      MAPVIEW = null;
      //link.remove();
    };
  });

  onDestroy(() => {
    if (MAPVIEW) MAPVIEW.remove();
    MAPVIEW = null;
  });
</script>

<figure class="map" bind:this={container}>
  {#if MAPVIEW}
    <slot />
  {/if}
</figure>
