import { param } from "../../util/params";
import { propertyId } from "../../util/stores";
import { fetchPropertyGeoFeaturesStatic } from "./api";
import { writable, derived, readable } from "svelte/store";
import { get } from "lodash-es";

export const mapbranch = derived(
  param("branch", true),
  ($value) => $value || "main"
);

export const builderbranch = derived(
  param("builder", true),
  ($value) => $value || "main"
);

let initLevel = null;

export const level = derived(param("level"), ($level) => {
  if (null == $level) return null;
  if ($level === "") return null;
  return $level;
});

level.subscribe(($level) => {
  if (null == initLevel && null != $level) initLevel = $level;
});

const geojson = param("geojson");
export const custom = param("geojson", true);

export const position = derived(
  param("position"),
  ($value) => $value && $value.split(",").map(parseFloat)
);

export const propertyGeoJsonCustom = writable();

// let activeBranch;
// export const propertyGeoJsonStatic = derived([propertyId, mapbranch, geojson, propertyGeoJsonCustom], async([$propertyId, $branch, $url, $custom], set) => {

//     if($custom) return set($custom);

//     if($branch != activeBranch) activeBranch = $branch;

//     if (!$propertyId) return set({
//         "type": "FeatureCollection",
//         "features": [],
//     });

//     console.log("fetching geojson=", $propertyId, $branch);

//     if($url) {
//         return set(JSON.parse(await (fetch($url).then(res => res.text()))));
//     }

//     await fetchPropertyGeoFeaturesStatic($propertyId, $branch)

//     const json = await fetchPropertyGeoFeaturesStatic($propertyId, $branch);
//     console.log("testingset=", $branch, activeBranch);
//     if($branch == activeBranch) set(json); // make sure this branch is still active

//     //return await fetchAndStorePropertyGeojson($propertyId);
// }, {
//     "type": "FeatureCollection",
//     "features": [],
// });

let activeBranch;
export const propertyGeoJsonStatic = derived(
  [propertyId, mapbranch, builderbranch, geojson, propertyGeoJsonCustom],
  async ([$propertyId, $branch, $builder, $url, $custom], set) => {
    if ($custom) return set($custom);

    if ($branch != activeBranch) activeBranch = $branch;

    if (!$propertyId)
      return set({
        type: "FeatureCollection",
        features: [],
      });

    //console.log("fetching geojson=", $propertyId, $branch);

    if ($url) {
      return set(JSON.parse(await fetch($url).then((res) => res.text())));
    }

    // fast load outside
    let outside = await fetchPropertyGeoFeaturesStatic(
      $propertyId,
      $branch,
      $builder,
      initLevel || ""
    );
    if ($branch == activeBranch) set(outside);

    // await completed
    const fetchEntire = fetchPropertyGeoFeaturesStatic(
      $propertyId,
      $branch,
      $builder
    );

    const json = await fetchEntire;
    //console.log("testingset=", $branch, activeBranch);
    if ($branch == activeBranch) set(json); // make sure this branch is still active

    //return await fetchAndStorePropertyGeojson($propertyId);
  },
  {
    type: "FeatureCollection",
    features: [],
  }
);
