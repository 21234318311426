import { each, debounce, throttle, unset, get } from "lodash-es";
import bbox from "geojson-bbox";
import mapbox from "mapbox-gl";


mapbox.accessToken = "pk.eyJ1IjoicGFya2luZ2Jvc3MiLCJhIjoiY2swY3VheHQyMDE1ejNtbjV4M3RoeTQ5cyJ9.toumXl_aMY5GgH45lZyiuA";

const key = {};
const draw = window.MapboxDraw;

export { mapbox, key, draw as MapboxDraw };



export function maxBoundsFromGeoJSON(map, geojson, padding) {
    if(!geojson || !geojson.features || !geojson.features.length) return;

    var bounds = bbox(geojson);
    // map.fitBounds([[ bounds[0], bounds[1] ], [ bounds[2], bounds[3] ]], {
    //     padding: padding || 0,
    //     animate:false,
    // });
    // var scaled = bbox(transformScale(bboxPolygon(bounds), 3));
    // //console.log("scaled", scaled);
    // map.setMaxBounds([[ scaled[0], scaled[1] ], [ scaled[2], scaled[3] ]])
}

export function boundsFromGeoJSON(map, geojson, padding) {

    if(!geojson || !geojson.features || !geojson.features.length) return;

    console.log("bounds=", geojson.bbox);
    var bounds = geojson.bbox || bbox(geojson);
    map.fitBounds(bounds, {
        padding: padding || 0,
        animate:false,
    });
    //var scaled = bbox(transformScale(bboxPolygon(bounds), 3));
    //console.log("scaled", scaled);
    //map.setMaxBounds([[ scaled[0], scaled[1] ], [ scaled[2], scaled[3] ]])
}

export function boundsFromBbox(map, bounds, padding) {
    map.fitBounds(bounds, {
        padding: padding || 0,
        animate:false,
    });
    // var scaled = bbox(transformScale(bboxPolygon(bounds), 3));
    // //console.log("scaled", scaled);
    // map.setMaxBounds([[ scaled[0], scaled[1] ], [ scaled[2], scaled[3] ]])
}

export function updateStyle(map, style, data, layers) {
    if(!style) return;

    if(style.sources && data) {

        console.log("updating sources=", style.sources, data);

        // update the source data
        for(const [ id, geojson ] of Object.entries(data)) {
            style.sources[id] = Object.assign(style.sources[id] || {
                type: geojson.type
            }, {
                data: geojson.data
            })
        }

    }

    // if(layers) {
    //     for(const layer of Object.values(layers)) {
    //         style.layers.push(layer);
    //     }
    // }

    console.log("setting style=", style);

    map.setStyle(style);

}

function normalizeLayers(layers) {
    if(Array.isArray(layers)) return layers;
    return Object.entries(layers).reduce((result, [ id, value]) => {
        if(!value.id) value.id = id;
        result.push(value);
        return result;
    }, []);
}

export function updateSources(map, sources, layers) {

    console.log("updating sources=", sources);

    if(sources) {

        for(const [ id, source ] of Object.entries(sources)) {

            var existing = map.getSource(id);
            console.log("existing=", existing);
            if(!!existing) existing.setData(source.data);
            else map.addSource(id, source);
            existing = map.getSource(id);
            //if(source.attribution) console.log("source attribution  = ", source.attribution);
            //if(source.attribution) existing.attribution = source.attribution;
            console.log("new source data=", id, existing);
            
        }
    }

    console.log("updating layers=", layers);

    if(layers) {

        for(const layer of normalizeLayers(layers)) {

            console.log("checking layer=", layer);

            var existing = map.getLayer(layer.id);
            if(!existing) map.addLayer(layer);
            existing = map.getLayer(layer.id);
            console.log("layer status=", existing);
            
        }
    }

}

export function updateData(map, data) {


    if(!data) return;

    each(data, function(value, id) {
        var source = map.getSource(id);
        if(!!source) source.setData(value);
        else map.addSource(id, {
            type:"geojson",
            data:value,
        });
        source = map.getSource(id);
        //boundsFromGeoJSON(map, value);
    });

}



export function mapBBox(mapUI) {
    return mapUI.getBounds().toArray().flat();
}

export const tileSources = {
    "mapbox-satellite-tiles": {
        "type": "raster",
        "url": "mapbox://mapbox.satellite",
        "tileSize": 256
    },
    "osm-tiles": {
        "type": "raster",
        "tiles": [
            "https://a.tile.openstreetmap.org/{z}/{x}/{y}.png",
            "https://b.tile.openstreetmap.org/{z}/{x}/{y}.png",
            "https://c.tile.openstreetmap.org/{z}/{x}/{y}.png",
        ],
        "tileSize": 256,
        "attribution":'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>'
    }
}

export const tileLayers = {
    "mapbox-satellite-tiles": {
        "id": "mapbox-satellite-tiles",
        "type": "raster",
        "source":"mapbox-satellite-tiles"
    },
    "osm-tiles": {
        "id": "osm-tiles",
        "type": "raster",
        "source":"osm-tiles"
    }
}