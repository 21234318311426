import { client as mqtt } from "./mqtt";
import { readable } from "svelte/store";

const monitors = {}

const mqttClient = mqtt("AKIAIUPPRVWKBYHY4UWQ", "GQQeZRDLfbR9JpVeIuAJzcAOgSlaJXABCRsqR3M8");

export function updated(propertyId, subscriber) {

    if(!propertyId) return null;

    let monitor = monitors[propertyId];
    if(monitor && monitor.store) return monitor.store.subscribe($value => {
        if($value && monitor.value && monitor.value.updated == $value.updated) return; // null up
        subscriber($value);
        monitor.value = $value;
    });//.store;

    console.log("setting up monitor=", propertyId);

    monitor = monitors[propertyId] = {
        id:propertyId,
    };

    const store = monitor.store = readable(null, function start(set) {

        // called on our first subscriber
        const subscribetopic = `locations/${propertyId}`;

        mqttClient.on("message", function(topic, payload) {
            const json = JSON.parse(payload.toString());
            console.log("mqtt.message=", json, topic, payload);
            if(topic != subscribetopic) return;
            const value = {
                scope: propertyId,
                type: Object.keys(json)[0],
                updated: json[Object.keys(json)[0]],
            };
            set(value);
            monitor.value = value; // set after the store update
        });

        // start the connection
        console.log("mqtt.subscribe=", subscribetopic);
        mqttClient.subscribe(subscribetopic, function(err, granted) {
            console.log("mqtt.subscribed", err, granted);
        });

        return function stop() {
            // disconnect when we have no subscribers
            console.log("mqtt.unsubscribe=", subscribetopic);
            mqttClient.unsubscribe(subscribetopic, console.log);
        }
    });

    return monitor.store.subscribe($value => {
        if($value && monitor.value && monitor.value.updated == $value.updated) return; // null up
        subscriber($value);
        monitor.value = $value;
    });//.store;

}
export default updated;