import { writable, derived, readable } from "svelte/store";
import { param } from "./params";
import { items } from "@parkingboss/svelte-utils";
import {
  fetchAndStoreProperty,
  fetchAndStorePolicies,
  fetchAndStoreUnits,
  fetchAndStoreSpaces,
  fetchSpaceStatus,
  fetchPermits,
} from "../api";
import { sortBy } from "./sort";
import { updated as monitor } from "./updated";
import { ensureLoggedIn } from "./auth";

export const view = param("view", true);
export const valid = param("valid");
export const propertyId = param("property", true);
export const unitId = param("unit");
export const selectedId = param("selected");
export const mode = param("mode", false, "parking");
export const parking = derived(
  [mode, param("parking", false, "permitted")],
  ([$mode, $param]) => $mode == "parking" && $param
);
export const advanced = derived(
  param("adv"),
  ($value) => $value === "true" || $value === true
);
export const loadPermits = derived(
  param("permits"),
  ($value) => $value === "true" || $value === true
);
export const admin = derived(
  param("admin"),
  ($value) => $value === "true" || $value === true
);

admin.subscribe(($value) => {
  if ($value) ensureLoggedIn(true);
});

export { param, items };

export const spaceId = param("space");

propertyId.subscribe(($propertyId) => {
  if (!$propertyId) return;
  fetchAndStoreProperty($propertyId);
  fetchAndStorePolicies($propertyId);
  fetchAndStoreUnits($propertyId);
  fetchAndStoreSpaces($propertyId);
});

export const selected = derived([selectedId, items], ([$selected, state]) => {
  return $selected && state[$selected];
});

export const prices = derived([propertyId, items], ([property, state]) => {
  if (!property) return null;
  if (!state.prices || !state.prices.items) return null;
  return state.prices.items;
});

export const units = derived([propertyId, items], ([property, state]) => {
  if (!property) return null;
  if (!state.units || !state.units.items) return null;
  return state.units.items;
});

export const spaces = derived([propertyId, items], ([property, state]) => {
  if (!property) return null;
  if (!state.spaces || !state.spaces.items) return null;
  return sortBy(Object.values(state.spaces.items), "display");
});

export const policies = derived([propertyId, items], ([property, state]) => {
  if (!property) return null;
  if (!state.policies || !state.policies.items) return null;
  return state.policies.items;
});

export const property = derived(
  [propertyId, items],
  ([$id, $state]) => $id && $state[$id]
);

export const updated = derived(property, function onchange($property, set) {
  if (!$property) return set(null);

  const unsubscribe = monitor($property.id, set); // get the monitor
  // const unsubscribe = monitor.store.subscribe($value => {

  //     // smart check value
  //     if($value.scope == monitor.scope && $value.updated == $lastUpdated.updated) return;

  //     lastUpdated = $value;
  //     set($value);

  // }); // proxy

  return function nextchange() {
    if (unsubscribe) unsubscribe();
  }; // clear
});

// refetch when property, validity, or updated monitor changes
export const permitted = derived(
  [propertyId, valid, updated],
  async ([$propertyId, $valid, $updated], set) => {
    set(null);
    if (!$propertyId) return;
    const json = await fetchSpaceStatus($propertyId, $valid);
    var items = json.spaces.summary.items;
    for (const [id, item] of Object.entries(items)) {
      for (const key of ["space"]) {
        if (item[key] && typeof item[key] == "string")
          item[key] = json.items[item[key]] || item[key];
      }
    }
    set(items);
  }
);

export const permits = derived(
  [propertyId, valid, updated, loadPermits],
  async ([$propertyId, $valid, $updated, $load], set) => {
    console.log(
      "starting permits refresh",
      $propertyId,
      $valid,
      $updated,
      $load
    );

    set(null);
    if (!$propertyId) return;
    if (!$load) return;

    const json = await fetchPermits($propertyId, $valid);

    if (!json || !json.permits || !json.permits.items) return set([]);

    var items = json.permits.items;
    set(items);
  }
);

export const unit = derived(
  [unitId, items],
  ([$id, $state]) => $id && $state[$id]
);
export const space = derived(
  [spaceId, items],
  ([$id, $state]) => $id && $state[$id]
);

// logging
permits.subscribe(($value) => console.log("permits=", $value));
