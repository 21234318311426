import connect from "mqtt/dist/mqtt";
import HmacSHA256 from "crypto-js/hmac-sha256";
import SHA256 from "crypto-js/sha256";
import Hex from "crypto-js/enc-hex";

// const digest = crypto
// .createHash('sha256')
// .update('', 'utf8')
// .digest('hex');

// const signedUrl = ({ credentials, endpoint, region, expires }) => {
//     const payload =
//     return v4.createPresignedURL('GET', endpoint, '/mqtt', 'iotdevicegateway', payload, {
//       key: credentials.accessKeyId,
//       secret: credentials.secretAccessKey,
//       sessionToken: credentials.sessionToken,
//       protocol: 'wss',
//       region: region,
//       expires: expires,
//     })
//   }
function toTime(time) {
  return new Date(time).toISOString().replace(/[:\-]|\.\d{3}/g, "");
}

function toDate(time) {
  return toTime(time).substring(0, 8);
}
const SigV4Utils = {};
SigV4Utils.sign = function (key, msg) {
  var hash = HmacSHA256(msg, key);
  return hash.toString(Hex);
};

SigV4Utils.sha256 = function (msg) {
  var hash = SHA256(msg);
  return hash.toString(Hex);
};

SigV4Utils.getSignatureKey = function (
  key,
  dateStamp,
  regionName,
  serviceName
) {
  var kDate = HmacSHA256(dateStamp, "AWS4" + key);
  var kRegion = HmacSHA256(regionName, kDate);
  var kService = HmacSHA256(serviceName, kRegion);
  var kSigning = HmacSHA256("aws4_request", kService);
  return kSigning;
};
function getSignedUrl(regionName, awsIotEndpoint, accessKey, secretKey) {
  var time = new Date();
  var dateStamp = toDate(time);
  var amzdate = toTime(time);
  var service = "iotdevicegateway";
  var region = regionName;
  var secretKey = secretKey;
  var accessKey = accessKey;
  var algorithm = "AWS4-HMAC-SHA256";
  var method = "GET";
  var canonicalUri = "/mqtt";
  var host = awsIotEndpoint;
  var credentialScope =
    dateStamp + "/" + region + "/" + service + "/" + "aws4_request";
  var canonicalQuerystring = "X-Amz-Algorithm=AWS4-HMAC-SHA256";
  canonicalQuerystring +=
    "&X-Amz-Credential=" +
    encodeURIComponent(accessKey + "/" + credentialScope);
  canonicalQuerystring += "&X-Amz-Date=" + amzdate;
  canonicalQuerystring += "&X-Amz-SignedHeaders=host";
  var canonicalHeaders = "host:" + host + "\n";
  var payloadHash = SigV4Utils.sha256("");
  var canonicalRequest =
    method +
    "\n" +
    canonicalUri +
    "\n" +
    canonicalQuerystring +
    "\n" +
    canonicalHeaders +
    "\nhost\n" +
    payloadHash;
  var stringToSign =
    algorithm +
    "\n" +
    amzdate +
    "\n" +
    credentialScope +
    "\n" +
    SigV4Utils.sha256(canonicalRequest);
  var signingKey = SigV4Utils.getSignatureKey(
    secretKey,
    dateStamp,
    region,
    service
  );
  var signature = SigV4Utils.sign(signingKey, stringToSign);
  canonicalQuerystring += "&X-Amz-Signature=" + signature;
  //canonicalQuerystring += '&X-Amz-Security-Token=' + encodeURIComponent(AWS.config.credentials.sessionToken);
  return "wss://" + host + canonicalUri + "?" + canonicalQuerystring;
}

export function client(key, secret, onmessage) {
  const client = connect("ws://localhost", {
    transformWsUrl: function (url, options, client) {
      return getSignedUrl(
        "us-east-1",
        "a2rxme8um7p462-ats.iot.us-east-1.amazonaws.com",
        key,
        secret
      );
    },
  });
  if (onmessage)
    client.on("message", function (topic, payload) {
      //console.log("mesage", topic, JSON.parse(payload.toString()));
      onmessage(JSON.parse(payload.toString()));
    });
  client.on("error", (e) => {
    //console.log("mqtt.error=", e)
  });
  client.on("connect", function (e) {
    //console.log("mqtt.connect=", e);
  });

  return client;
}

export function topic(id, key, secret, onmessage) {
  const client = connect("ws://localhost", {
    transformWsUrl: function (url, options, client) {
      //return signedUrl()

      // return Signer.signUrl("wss://a2rxme8um7p462-ats.iot.us-east-1.amazonaws.com/mqtt", {
      //     access_key:key,
      //     secret_key:secret,
      // }, {
      //     region:"us-east-1",
      //     service:"iotdevicegateway"
      // });

      return getSignedUrl(
        "us-east-1",
        "a2rxme8um7p462-ats.iot.us-east-1.amazonaws.com",
        key,
        secret
      );
      // return "wss://" + SigV4Utils.getSignedUrl({
      //     service: 'iotdevicegateway',
      //     hostname: 'a2rxme8um7p462-ats.iot.us-east-1.amazonaws.com',
      //     path: '/mqtt',
      //     region: 'us-east-1',
      //     method: 'GET',
      // }, {
      //     accessKeyId:key,
      //     secretAccessKey:secret,
      // });

      // It's possible to inspect some state on options(pre parsed url components)
      // and the client (reconnect state etc)
      // return v4.createPresignedURL("GET", "a2rxme8um7p462-ats.iot.us-east-1.amazonaws.com", "/mqtt", "iotdevicegateway", digest, {
      //     key: key,
      //     secret: secret,
      //     protocol: "wss",
      //     region: "us-east-1",
      // });
    },
  });
  client.on("message", function (topic, payload) {
    //console.log("mesage", topic, JSON.parse(payload.toString()));
    onmessage(JSON.parse(payload.toString()));
  });
  client.on("error", (error) => {
    //console.log(error)
  });
  client.on("connect", function () {
    client.subscribe("locations/" + id);
  });

  return client;
}
