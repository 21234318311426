import { api, viewpoint, base } from "../../api";

export async function fetchPropertyGeoFeatures(property) {
  const res = await fetch(
    `${api.settings.apiBase}/geo?viewpoint=${viewpoint()}&scope=${
      property.id || property
    }&valid=${viewpoint()}/`
  );
  const json = await res.json();
  return json;
}

export async function fetchPropertyGeoBase(property, branch) {
  const res = await fetch(
    `${api.settings.apiBase}/properties/${
      property.id || property
    }/map?viewpoint=${viewpoint()}&branch=${branch || ""}`
  );
  //const res = await fetch(`https://customapps.parkingboss.com/geojson/master/${property.uuid.replace("-","")}.json`);
  const json = await res.json();
  return json;
}

// export async function fetchPropertyGeoFeaturesStatic(scope, branch) {
//     let url = `${base()}/properties/${scope}/map?viewpoint=${viewpoint()}`;
//     if(!!branch) url += `&branch=${branch}`;
//     console.log("map url=", url);
//     const res = await fetch(url);
//     //console.log("map res", res);
//     if(!res.ok) return {
//         "type":"FeatureCollection",
//         "features":[]
//     };
//     const json = await res.json();

//     return json;
// }

export async function fetchPropertyGeoFeaturesStatic(
  scope,
  branch,
  builder,
  level
) {
  //let url = `${base()}/properties/${scope}/map?viewpoint=${viewpoint()}`;
  const url = new URL("https://carto.communityboss.app?property=" + scope);
  if (!!branch) url.searchParams.set("branch", branch);
  if (!!builder) url.searchParams.set("builder", builder);
  if (null != level) url.searchParams.set("level", level);

  const res = await fetch(url);

  if (!res.ok)
    return {
      type: "FeatureCollection",
      features: [],
    };

  //console.log("map res", res);
  const json = await res.json();

  return json;
}
